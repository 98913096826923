<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <div id="my-upload" style="display: flex; justify-content: space-evenly">
          
            <vue-upload-multiple-image
              :key="`icone-${idupload}`"
              idUpload="myIconeImage"
              @upload-success="(fd, i, fl) => uploadImageSuccess('icone', fd, i, fl)"
              @before-remove="(fd, i, fl) => beforeRemove('icone', fd, i, fl)"
              @edit-image="(fd, i, fl) => editImage('icone', fd, i, fl)"
              @data-change="dataChange"
              :data-images="pathImageIcone"
              :dragText="components.picOptions.dragText"
              browseText="Logo"
              :primaryText="components.picOptions.primaryText"
              :markIsPrimaryText="components.picOptions.markIsPrimaryText"
              :popupText="components.picOptions.popupText"
              :dropText="components.picOptions.dropText"
              :showPrimary="false"
              :showEdit="false"
              :maxImage="1"
              :multiple="false"
            ></vue-upload-multiple-image>
           <vue-upload-multiple-image
              :key="`logo-${idupload}`"
              idUpload="myLogoImage"
              @upload-success="(fd, i, fl) => uploadImageSuccess('logo', fd, i, fl)"
              @before-remove="(fd, i, fl) => beforeRemove('logo', fd, i, fl)"
              @data-change="dataChange"
              :data-images="pathImageLogo"
              :dragText="components.picOptions.dragText"
              browseText="Card Principal"
              :primaryText="components.picOptions.primaryText"
              :markIsPrimaryText="components.picOptions.markIsPrimaryText"
              :popupText="components.picOptions.popupText"
              :dropText="components.picOptions.dropText"
              :showPrimary="false"
              :showEdit="false"
              :maxImage="1"
              :multiple="false"
            ></vue-upload-multiple-image>
            <vue-upload-multiple-image
              :key="`image1-${idupload}`"
              idUpload="myImage1"
              @upload-success="(fd, i, fl) => uploadImageSuccess('image1', fd, i, fl)"
              @before-remove="(fd, i, fl) => beforeRemove('image1', fd, i, fl)"
              @edit-image="(fd, i, fl) => editImage('image1', fd, i, fl)"
              @data-change="dataChange"
              :data-images="pathImageImage1"
              :dragText="components.picOptions.dragText"
              browseText="Imagem 1"
              :primaryText="components.picOptions.primaryText"
              :markIsPrimaryText="components.picOptions.markIsPrimaryText"
              :popupText="components.picOptions.popupText"
              :dropText="components.picOptions.dropText"
              :showPrimary="false"
              :showEdit="false"
              :maxImage="1"
              :multiple="false"
            ></vue-upload-multiple-image>
            <vue-upload-multiple-image
              :key="`image2-${idupload}`"
              idUpload="myImage2"
              @upload-success="(fd, i, fl) => uploadImageSuccess('image2', fd, i, fl)"
              @before-remove="(fd, i, fl) => beforeRemove('image2', fd, i, fl)"
              @edit-image="(fd, i, fl) => editImage('image2', fd, i, fl)"
              @data-change="dataChange"
              :data-images="pathImageImage2"
              :dragText="components.picOptions.dragText"
              browseText="Imagem 2"
              :primaryText="components.picOptions.primaryText"
              :markIsPrimaryText="components.picOptions.markIsPrimaryText"
              :popupText="components.picOptions.popupText"
              :dropText="components.picOptions.dropText"
              :showPrimary="false"
              :showEdit="false"
              :maxImage="1"
              :multiple="false"
            ></vue-upload-multiple-image>
          </div>
<br/>
  <b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.solicitacao_pendente==true">
            <div class='alert alert-warning'>
               Existe uma solicitação realizada no portal pendente de aprovação, confira os dados abaixo!
            </div>
          </b-row>
</b-row>
          <b-row>
            <b-input-group size="sm">
              <b-form-checkbox
                name="status"
                id="status"
                v-model="form.status"
                value="1"
              >
                <span v-if="form.status == 1">Ativo</span>
                <span v-else>Inativo</span>
              </b-form-checkbox>
            </b-input-group>
          </b-row>
           

 
           <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text
                >Tipo de Equipamento:</b-input-group-prepend
              >
              <b-form-select
                id="tab_tipo_equipamento_id"
                name="tab_tipo_equipamento_id"
                v-model="form.tab_tipo_equipamento_id"
                :options="selects.tipoequipamento"
                size="sm"
              />
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Nome :
              </b-input-group-prepend>
              <b-form-input
                id="nome"
                type="text"
                name="nome"
                maxlength="100"
                v-model="form.nome"
                placeholder="Digite o nome"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.nome!=form_old.nome">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.nome}}
            </b-input-group>
          </b-row>
          
          <div class="">
            <div class="">
              <p>Dados da Localização</p>
            </div>
          </div>
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Lozalização Estado:
              </b-input-group-prepend>
              <b-form-input
                id="localizacao_estado"
                type="text"
                name="localizacao_estado"
                v-model="form.localizacao_estado"
                placeholder="Digite o Estado"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.localizacao_estado!=form_old.localizacao_estado">
             <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.localizacao_estado}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Localização Cidade:
              </b-input-group-prepend>
              <b-form-input
                id="localizacao_cidade"
                type="text"
                name="localizacao_cidade"
                v-model="form.localizacao_cidade"
                placeholder="Digite a Cidade"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.localizacao_cidade!=form_old.localizacao_cidade">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.localizacao_cidade}}
            </b-input-group>
          </b-row>
       

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Localização - Endereço rua/avenida:
              </b-input-group-prepend>
              <b-form-input
                id="localizacao"
                type="text"
                name="localizacao"
                v-model="form.localizacao"
                placeholder="Digite o Localização"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.localizacao!=form_old.localizacao">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.localizacao}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Localização - Bairro:
              </b-input-group-prepend>
              <b-form-input
                id="localizacao_bairro"
                type="text"
                name="bairro"
                v-model="form.localizacao_bairro"
                placeholder="Digite o bairro"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.localizacao_bairro!=form_old.localizacao_bairro">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.localizacao_bairro}}
            </b-input-group>
          </b-row>
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Localização - detalhes/referência:
              </b-input-group-prepend>
              <b-form-input
                id="localizacao_referencia"
                type="text"
                name="bairro"
                v-model="form.localizacao_referencia"
                placeholder="Digite a referência"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.localizacao_referencia!=form_old.localizacao_referencia">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.localizacao_referencia}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Localização - Data de Início:
              </b-input-group-prepend>
              <b-form-input
                id="localizacao"
                type="text"
                name="localizacao"
                v-model="form.localizacao_data_inicio"
               v-mask="'##/##/####'"
                placeholder="dd/mm/YYYY"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.localizacao_data_inicio!=form_old.localizacao_data_inicio">
           <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.localizacao_data_inicio}}
            </b-input-group>
          </b-row>

           <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                  Localização - Data de Final:
              </b-input-group-prepend>
              <b-form-input
                id="localizacao"
                type="text"
                name="localizacao"
                v-model="form.localizacao_data_fim"
               v-mask="'##/##/####'"
                placeholder="dd/mm/YYYY" >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.localizacao_data_fim!=form_old.localizacao_data_fim">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.localizacao_data_fim}}
            </b-input-group>
          </b-row>
         
         
          <div class="">
            <div class="">
              <p>Mídias digitais e contatos</p>
            </div>
          </div>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Site:
              </b-input-group-prepend>
              <b-form-input
                id="site"
                type="text"
                name="site"
                v-model="form.site"
                placeholder="Digite o Site"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.site!=form_old.site">
          <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.site}}
            </b-input-group>
          </b-row>

        
         

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                E-mail:
              </b-input-group-prepend>
              <b-form-input
                id="email_equipamento"
                type="text"
                name="email_equipamento"
                v-model="form.email_equipamento"
                placeholder="Digite o E-mail:"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.email_equipamento!=form_old.email_equipamento">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.email_equipamento}}
            </b-input-group>
          </b-row>

       
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Whatsapp:
              </b-input-group-prepend>
              <b-form-input
                id="whatsapp"
                type="text"
                name="whatsapp"
                v-model="form.whatsapp"
                placeholder="Digite o Whatsapp:"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.whatsapp!=form_old.whatsapp">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.whatsapp}}
            </b-input-group>
          </b-row>
   <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Facebook:
              </b-input-group-prepend>
              <b-form-input
                id="facebook"
                type="text"
                name="facebook"
                v-model="form.facebook"
                placeholder="Digite o Facebook:"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.facebook!=form_old.facebook">
             <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.facebook}}
            </b-input-group>
          </b-row>
         
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Instagran:
              </b-input-group-prepend>
              <b-form-input
                id="instagran"
                type="text"
                name="instagran"
                v-model="form.instagran"
                placeholder="Digite o Instagran:"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.instagran!=form_old.instagran">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.instagran}}
            </b-input-group>
          </b-row>

          
       
        <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                TikTok:
              </b-input-group-prepend>
              <b-form-input
                id="tiktok"
                type="text"
                name="tiktok"
                v-model="form.link_tiktok"
                placeholder="Digite o TikTok:"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.link_tiktok!=form_old.link_tiktok">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.link_tiktok}}
            </b-input-group>
          </b-row>
            
    
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                YouTube:
              </b-input-group-prepend>
              <b-form-input
                id="youtube"
                type="text"
                name="youtube"
                v-model="form.link_canal_youtube"
                placeholder="Digite o canal do Youtube:"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.link_canal_youtube!=form_old.link_canal_youtube">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.link_canal_youtube}}
            </b-input-group>
          </b-row>

           
          
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Telefone:
              </b-input-group-prepend>
              <b-form-input
                id="telefone_equipamento"
                type="text"
                name="telefone_equipamento"
                v-model="form.telefone_equipamento"
                placeholder="Digite o Telefone"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.telefone_equipamento!=form_old.telefone_equipamento">
           <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.telefone_equipamento}}
            </b-input-group>
          </b-row>



          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Vídeo 1:
              </b-input-group-prepend>
              <b-form-input
                id="link_video1"
                type="text"
                name="link_video1"
                v-model="form.link_video1"
                placeholder="Digite o link:"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.link_video1!=form_old.link_video1">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.link_video1}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Vídeo 2:
              </b-input-group-prepend>
              <b-form-input
                id="link_video2"
                type="text"
                name="link_video2"
                v-model="form.link_video2"
                placeholder="Digite o link:"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.link_video2!=form_old.link_video2">
             <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.link_video2}}
            </b-input-group>
          </b-row>
      
          <b-row class="mb-3 hidden">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Link Ingresso Físico:
              </b-input-group-prepend>
              <b-form-input
                id="link_ingresso_fisico"
                type="text"
                name="link_ingresso_fisico"
                v-model="form.link_ingresso_fisico"
                placeholder="Link Ingresso Físico"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.link_ingresso_fisico!=form_old.link_ingresso_fisico">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.link_ingresso_fisico}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Link Ingresso Online:
              </b-input-group-prepend>
              <b-form-input
                type="text"
                name="link_ingresso_online"
                id="link_ingresso_online"
                v-model="form.link_ingresso_online"
                placeholder="Link Ingresso Online"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.link_ingresso_online!=form_old.link_ingresso_online">
             <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.link_ingresso_online}}
            </b-input-group>
          </b-row>


          <div class="">
            <div class="">
              <p>Dados da Empresa</p>
            </div>
          </div>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Razão Social :
              </b-input-group-prepend>
              <b-form-input
                id="razao_social"
                type="text"
                name="razao_social"
                maxlength="100"
                v-model="form.razao_social"
                placeholder="Digite a razão social"
              >
            
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.razao_social!=form_old.razao_social">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.razao_social}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Nome Fantasia :
              </b-input-group-prepend>
              <b-form-input
                id="nome_fantasia"
                type="text"
                name="nome_fantasia"
                maxlength="100"
                v-model="form.nome_fantasia"
                placeholder="Digite o nome fantasia"
              >
            
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.nome_fantasia!=form_old.nome_fantasia">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.nome_fantasia}}
            </b-input-group>
          </b-row>
        
         <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                CNPJ :
              </b-input-group-prepend>
              <b-form-input
                id="cnpj"
                type="text"
                name="cnpj"
                v-mask="'##.###.###/####-##'"
                maxlength="100"
                v-model="form.cnpj"
                placeholder="Digite o nome fantasia"
              >
            
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.cnpj!=form_old.cnpj">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.cnpj}}
            </b-input-group>
          </b-row>
        
                  <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
               Sede- Estado:
              </b-input-group-prepend>
              <b-form-input
                id="estado"
                type="text"
                name="estado"
                v-model="form.estado"
                placeholder="Digite o Estado"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.estado!=form_old.estado">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.estado}}
            </b-input-group>
          </b-row>      

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Sede - Cidade:
              </b-input-group-prepend>
              <b-form-input
                id="cidade"
                type="text"
                name="cidade"
                v-model="form.cidade"
                placeholder="Digite a Cidade"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
           <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.cidade!=form_old.cidade">
                <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.cidade}}
            </b-input-group>
          </b-row>

        <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Sede - rua/avenida:
              </b-input-group-prepend>
              <b-form-input
                id="endereco"
                type="text"
                name="endereco"
                v-model="form.endereco"
                placeholder="Digite o Endereço"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.endereco!=form_old.endereco">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.endereco}}
            </b-input-group>
          </b-row>
            <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Sede - bairro:
              </b-input-group-prepend>
              <b-form-input
                id="sede_bairro"
                type="text"
                name="sede_bairro"
                v-model="form.sede_bairro"
                placeholder="Digite o Endereço"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.sede_bairro!=form_old.sede_bairro">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.sede_bairro}}
            </b-input-group>
          </b-row>
   <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Sede - CEP:
              </b-input-group-prepend>
              <b-form-input
                id="cep"
                type="text"
                name="cep"
                maxlength="10"
                v-model="form.cep"
                placeholder="Digite o CEP"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.cep!=form_old.cep">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.cep}}
            </b-input-group>
          </b-row>
     

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Sede -Telefone:
              </b-input-group-prepend>
              <b-form-input
                id="sede_telefone"
                type="text"
                name="sede_telefone"
                v-model="form.sede_telefone"
                placeholder="Telefone"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.sede_telefone!=form_old.sede_telefone">  
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.sede_telefone}}
            </b-input-group>
          </b-row>
<b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                  Data de Fundação:
              </b-input-group-prepend>
              <b-form-input
                id="data_fundacao"
                type="text"
                name="data_fundacao"
                v-model="form.data_fundacao"
               v-mask="'##/##/####'"
                placeholder="dd/mm/YYYY" >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.data_fundacao!=form_old.data_fundacao">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.data_fundacao}}
            </b-input-group>
          </b-row>


          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Quantidade de Integrantes:
              </b-input-group-prepend>
              <b-form-input
                id="qtde_integrantes"
                type="text"
                name="qtde_integrantes"
                v-model="form.qtde_integrantes"
                placeholder="Quantidade de Integrantes"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.qtde_integrantes!=form_old.qtde_integrantes">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.qtde_integrantes}}
            </b-input-group>
          </b-row>



          <div class="">
            <div class="">
              <p>Proprietário</p>
            </div>
          </div>
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Proprietário - nome:
              </b-input-group-prepend>
              <b-form-input
                id="proprietario_nome"
                type="text"
                name="proprietario_nome"
                v-model="form.proprietario_nome"
                placeholder="proprietario_nome"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.proprietario_nome!=form_old.proprietario_nome">
             <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.proprietario_nome}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Proprietário - CPF:
              </b-input-group-prepend>
              <b-form-input
                id="proprietario_cpf"
                type="text"
                v-mask="'###.###.###-##'"
                name="proprietario_cpf"
                v-model="form.proprietario_cpf"
                placeholder="CPF do Proprietário"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.proprietario_cpf!=form_old.proprietario_cpf">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.proprietario_cpf}}
            </b-input-group>
          </b-row>
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Proprietário - telefone:
              </b-input-group-prepend>
              <b-form-input
                id="proprietario_telefone"
                type="text"
                name="proprietario_telefone"
                v-model="form.proprietario_telefone"
                placeholder="Telefone do Proprietário"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.proprietario_telefone!=form_old.proprietario_telefone">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.proprietario_telefone}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Proprietário - e-mail:
              </b-input-group-prepend>
              <b-form-input
                id="proprietario_email"
                type="text"
                name="proprietario_email"
                v-model="form.proprietario_email"
                placeholder="E-mail do Proprietário"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.proprietario_email!=form_old.proprietario_email">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.proprietario_email}}
            </b-input-group>
          </b-row>

          <div class="">
            <div class="">
              <p>Administrador</p>
            </div>
          </div>
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Administrador - nome:
              </b-input-group-prepend>
              <b-form-input
                id="responsavel"
                type="text"
                name="responsavel"
                v-model="form.responsavel"
                placeholder="Responsável"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.responsavel!=form_old.responsavel">
             <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.responsavel}}
            </b-input-group>
          </b-row>

      <b-row class="mb-3 hidden">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Administrador - CPF:
              </b-input-group-prepend>
              <b-form-input
                id="cpf_cnpj_responsavel"
                type="text"
                v-mask="'###.###.###-##'"
                name="cpf_cnpj_responsavel"
                v-model="form.cpf_cnpj_responsavel"
                placeholder="CPF do Administrador"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.cpf_cnpj_responsavel!=form_old.cpf_cnpj_responsavel">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.cpf_cnpj_responsavel}}
            </b-input-group>
          </b-row>
          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Administrador - telefone:
              </b-input-group-prepend>
              <b-form-input
                id="celular_responsavel"
                type="text"
                name="celular_responsavel"
                v-model="form.celular_responsavel"
                placeholder="Telefone do Administrador"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.celular_responsavel!=form_old.celular_responsavel">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.celular_responsavel}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Administrador - e-mail:
              </b-input-group-prepend>
              <b-form-input
                id="email_responsavel"
                type="text"
                name="email_responsavel"
                v-model="form.email_responsavel"
                placeholder="E-mail do Administrador"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.email_responsavel!=form_old.email_responsavel">
              <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.email_responsavel}}
            </b-input-group>
          </b-row>

          <b-row class="mb-3" v-if="form.loaded==true">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
              Administrador - senha:
              </b-input-group-prepend>
              <b-form-input
                id="senha_responsavel"
                type="password"
                name="senha_responsavel"
                v-model="form.senha_responsavel"
                placeholder="Não informar para manter a mesma salva"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.loaded==false">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
               Administrador - senha:
              </b-input-group-prepend>
              <b-form-input
                id="senha_responsavel"
                type="text"
                name="senha_responsavel"
                v-model="form.senha_responsavel"
                placeholder="Informar a senha para salvar"
              >
              </b-form-input>
            </b-input-group>
          </b-row>

        
          <b-row class="mb-3 hidden">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Telefone Fixo Responsável:
              </b-input-group-prepend>
              <b-form-input
                id="telefone_fixo_responsavel"
                type="text"
                name="telefone_fixo_responsavel"
                v-model="form.telefone_fixo_responsavel"
                placeholder=" Telefone Fixo Responsável"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.telefone_fixo_responsavel!=form_old.telefone_fixo_responsavel">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.telefone_fixo_responsavel}}
            </b-input-group>
          </b-row>

          
          

          
        

 <div class="">
            <div class="">
              <p>Dados Bancários</p>
            </div>
          </div>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Banco:
              </b-input-group-prepend>
              <b-form-input
                id="banco"
                type="text"
                name="banco"
                v-model="form.banco"
                placeholder="Digite o no do Banco"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.banco!=form_old.banco">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.banco}}
            </b-input-group>
          </b-row>


            <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Agência:
              </b-input-group-prepend>
              <b-form-input
                id="agencia"
                type="text"
                name="agencia"
                v-model="form.agencia"
                placeholder="Digite a Agência"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.agencia!=form_old.agencia">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.agencia}}
            </b-input-group>
          </b-row>


            <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                Conta:
              </b-input-group-prepend>
              <b-form-input
                id="conta"
                type="text"
                name="conta"
                v-model="form.conta"
                placeholder="Digite a Conta"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.conta!=form_old.conta">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.conta}}
            </b-input-group>
          </b-row>


          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text class="firstLabel">
                PIX:
              </b-input-group-prepend>
              <b-form-input
                id="pix"
                type="text"
                name="pix"
                v-model="form.pix"
                placeholder="Digite o PIX"
              >
              </b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.pix!=form_old.pix">
            <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.pix}}
            </b-input-group>
          </b-row>


          <div class="">
            <div class="">
              <p>Detalhes</p>
            </div>
          </div>

          <b-row class="mb-3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text>Detalhes:</b-input-group-prepend>
              <div class="col-12 m-0 p-0 containerMessagePromo">
                <textarea
                  class="bannerMessagePromo"
                  v-model="form.detalhes"
                  id="detalhes"
                  name="detalhes"
                  maxlength="1000"
                  placeholder="Digite aqui (Opcional)"
                ></textarea>
              </div>
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente==true && form.loaded==true &&  form.detalhes!=form_old.detalhes">
             <b-input-group size="sm" class=" red">
               <i class='fa fa-clock' title='Valor Anterior'></i> Valor Anterior: {{form_old.detalhes}}
            </b-input-group>
          </b-row>
          <b-row class="mb-3" v-if="form.solicitacao_pendente!=true">
            <b-button type="button" variant="success" size="sm" @click="save">
              <v-wait for="inprocess">
                <template slot="waiting"> Carregando... </template>
              </v-wait>
              <v-wait for="inprocessSave">
                <template slot="waiting"> Salvando... </template>
              </v-wait>
              <span v-if="!processing">Salvar</span>
            </b-button>
           <b-button class="ml-2" type="button" variant="success" size="sm" @click="saveContinue">
              <v-wait for="inprocess">
                <template slot="waiting"> Carregando... </template>
              </v-wait>
              <v-wait for="inprocessSave">
                <template slot="waiting"> Salvando... </template>
              </v-wait>
              <span v-if="!processing">Salvar e continuar</span>
            </b-button>
          </b-row>

          <b-row class="mb-3" v-if="form.solicitacao_pendente==true">
            <b-button type="button" variant="success" size="sm" @click="save">
              <v-wait for="inprocess">
                <template slot="waiting"> Carregando... </template>
              </v-wait>
              <v-wait for="inprocessSave">
                <template slot="waiting"> Processando... </template>
              </v-wait>
              <span v-if="!processing">Aprovar </span>
            </b-button>
           <b-button class="ml-2" type="button" variant="success" size="sm" @click="saveContinue">
              <v-wait for="inprocess">
                <template slot="waiting"> Carregando... </template>
              </v-wait>
              <v-wait for="inprocessSave">
                <template slot="waiting"> Processando... </template>
              </v-wait>
              <span v-if="!processing">Aprovar e continuar</span>
            </b-button>

            <b-button class="ml-2" type="button" variant="danger" size="sm" @click="recusar">
              <v-wait for="inprocess">
                <template slot="waiting"> Carregando... </template>
              </v-wait>
              <v-wait for="inprocessSave">
                <template slot="waiting"> Processando... </template>
              </v-wait>
              <span v-if="!processing">Recusar Alterações</span>
            </b-button>
          </b-row>


          </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import VueMask from "v-mask";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { func } from "@/functions";
import { equipamentoService } from "../../components/common/services/equipamento";
import { tipoequipamentoService } from "../../components/common/services/tipoequipamento";
import { Datetime } from 'vue-datetime';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const date = new Date();
const moment = extendMoment(Moment);
Vue.use(VueMask);

export default {
  mixins: [func],
  props: ["id"],
  components: { VueUploadMultipleImage, Datetime },
  name: "equipamento-add",
  head: {
    title: function () {
      return {
        inner: `TicketOffice | Admin`,
        separator: " | ",
        complement: `Equipamento - ${this.typeOf}`,
      };
    },
  },
  data() {
    return {
      idupload: "",
      processing: false,
      dataSolicitacao:false,
      loading: false,
      pathImageIcone: [],
      pathImageLogo: [],
      pathImageImage1: [],
      pathImageImage2: [],
      form: {
        imageURIBanner: "",
        imageURIOriginal: "",
        loaded: false,
        imgbase64: "",
        addedImages: [
          { id: 'logo', path: null },
          { id: 'icone', path: null },
          { id: 'image1', path: null },
          { id: 'image2', path: null }
        ],
        saveimage: 0,
        id: "",
        nome: "",
        cidade: "",
        estado: "",
        cep: "",
        telefone_equipamento: "",
        email_equipamento: "",
        whatsapp: "",
        facebook: "",
        instagran: "",
        qtde_integrantes: "",
        detalhes: "",
        link_ingresso_fisico: "",
        link_ingresso_online: "",
        responsavel: "",
        email_responsavel: "",
        celular_responsavel: "",
        telefone_fixo_responsavel: "",
        status: "",
        site: "",
        tab_tipo_equipamento_id: "",
        localizacao: "",
        link_canal_youtube: '',
        link_video1: '',
        link_video2: '',
        link_tiktok: '',
        localizacao_data_inicio:moment().format('DD/MM/YYYY'),
        localizacao_data_fim:moment().format('DD/MM/YYYY'),
        data_fundacao: moment().format('DD/MM/YYYY'),
        localizacao_cidade: '',
        localizacao_estado: '',
        cpf_cnpj_responsavel: '',
        senha_responsavel: '',
        razao_social: '',
        banco: '',
        agencia: '',
        conta: '',
        pix:''
      },
      form_old: {
        imageURIBanner: "",
        imageURIOriginal: "",
        loaded: false,
        imgbase64: "",
        addedImages: [
          { id: 'logo', path: null },
          { id: 'icone', path: null },
          { id: 'image1', path: null },
          { id: 'image2', path: null }
        ],
        saveimage: 0,
        id: "",
        nome: "",
        cidade: "",
        estado: "",
        cep: "",
        telefone_equipamento: "",
        email_equipamento: "",
        whatsapp: "",
        facebook: "",
        instagran: "",
        qtde_integrantes: "",
        detalhes: "",
        link_ingresso_fisico: "",
        link_ingresso_online: "",
        responsavel: "",
        email_responsavel: "",
        celular_responsavel: "",
        telefone_fixo_responsavel: "",
        status: "",
        site: "",
        tab_tipo_equipamento_id: "",
        localizacao: "",
        link_canal_youtube: '',
        link_video1: '',
        link_video2: '',
        link_tiktok: '',
        localizacao_data_inicio:'',
        localizacao_data_fim:'',
        
        localizacao_cidade: '',
        localizacao_estado: '',
        cpf_cnpj_responsavel: '',
        senha_responsavel: '',
        razao_social: '',
        banco: '',
        agencia: '',
        conta: '',
        pix:''
      },
      components: {
        picOptions: {
          dragText: "Arrastar imagem",
          browseText: "Selecione",
          primaryText: "Padrão",
          markIsPrimaryText: "Definir como padrão",
          popupText: "Esta imagem será exibida como padrão",
          dropText: "Solte aqui",
        },
   
      },
      selects: {
        tipoequipamento: [],
      },
    };
  },
  created() {
    if (!this.isAdd) {
      this.get();
    }
    this.populatetipoequipamento();
  },
  computed: {
    mayIsee() {
      return this.mayI("equipamento-add", "equipamento-viewer");
    },
    typeOf() {
      return this.isAdd ? "Adicionar" : "Alterar";
    },
    isAdd() {
      return this.id == "" || this.id == null || this.id == undefined;
    },
  },
  methods: {
   
    validate() {
        if (this.form.loaded ==false && this.form.senha_responsavel.length < 4) {
          // this.toastError("Preencha a Senha. (Mínimo 4 caracteres)");
        //  return false;
       }
      return true;
    },
    save() {
      if (this.processing) return;

      if (!this.validate()) return;

      this.processing = true;

      this.$wait.start("inprocessSave");
      this.showWaitAboveAll();

      equipamentoService
        .save(
          this.getLoggedId(),
          this.isAdd ? "" : this.id,
          this.form.saveimage,
          this.form.addedImages,
          this.form.tab_tipo_equipamento_id,
          this.form.status,
          this.form.nome,
          this.form.endereco,
          this.form.cidade,
          this.form.estado,
          this.form.cep,
          this.form.site,
          this.form.telefone_equipamento,
          this.form.email_equipamento,
          this.form.whatsapp,
          this.form.instagran,
          this.form.facebook,
          this.form.qtde_integrantes,
          this.form.detalhes,
          this.form.link_ingresso_fisico,
          this.form.link_ingresso_online,
          this.form.responsavel,
          this.form.email_responsavel,
          this.form.celular_responsavel,
          this.form.telefone_fixo_responsavel,
          this.form.localizacao,
          this.form.link_canal_youtube,
          this.form.link_video1,
          this.form.link_video2,
          this.form.link_tiktok,
          this.form.localizacao_cidade,
          this.form.localizacao_estado,
          this.form.localizacao_data_inicio,
          this.form.localizacao_data_fim,
          this.form.cpf_cnpj_responsavel,
          this.form.senha_responsavel,
          this.form.razao_social,
          this.form.banco,
          this.form.agencia,
          this.form.conta,
          this.form.pix,
          this.form.solicitacao_pendente,
          this.form.localizacao_bairro,
          this.form.localizacao_referencia,
          this.form.cnpj,
          this.form.nome_fantasia,
          this.form.sede_bairro,
          this.form.sede_telefone,
          this.form.proprietario_nome,
          this.form.proprietario_cpf,
          this.form.proprietario_telefone,
          this.form.proprietario_email,
          this.form.data_fundacao,
          
           //informação se vai aprovar a solicitação

        )
        .then(
          (response) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.$wait.end("inprocessSave");

            if (this.validateJSON(response)) {
              if (response.success) {
                this.toastSuccess("Salvo com sucesso");
                this.$router.push(`/equipamento/list`);
              } else {
                this.toastError(response.msg);
              }
            }
          },
          (error) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.$wait.end("inprocessSave");
            this.toastError("Falha na execução.");
          }
        );
    },
      recusar() {
          if (this.processing) return;
          this.$wait.start("inprocessSave");
          this.showWaitAboveAll();

      equipamentoService.recusar(
          this.getLoggedId(),
          this.isAdd ? "" : this.id).then(
          (response) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.$wait.end("inprocessSave");
            if (this.validateJSON(response)) {
              if (response.success) {
                this.toastSuccess("Recusado com sucesso");
                this.$router.push(`/equipamento/edit/`+response.id);
                this.form.solicitacao_pendente=false;
                this.form=this.form_old;
              } else {
                this.toastError(response.msg);
              }
            }
          },
          (error) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.$wait.end("inprocessSave");
            this.toastError("Falha na execução.");
          }
        );
    },
    saveContinue() {
      if (this.processing) return;

      if (!this.validate()) return;

      this.processing = true;

      this.$wait.start("inprocessSave");
      this.showWaitAboveAll();

      equipamentoService
        .save(
          this.getLoggedId(),
          this.isAdd ? "" : this.id,
          this.form.saveimage,
          this.form.addedImages,
          this.form.tab_tipo_equipamento_id,
          this.form.status,
          this.form.nome,
          this.form.endereco,
          this.form.cidade,
          this.form.estado,
          this.form.cep,
          this.form.site,
          this.form.telefone_equipamento,
          this.form.email_equipamento,
          this.form.whatsapp,
          this.form.instagran,
          this.form.facebook,
          this.form.qtde_integrantes,
          this.form.detalhes,
          this.form.link_ingresso_fisico,
          this.form.link_ingresso_online,
          this.form.responsavel,
          this.form.email_responsavel,
          this.form.celular_responsavel,
          this.form.telefone_fixo_responsavel,
          this.form.localizacao,
          this.form.link_canal_youtube,
          this.form.link_video1,
          this.form.link_video2,
          this.form.link_tiktok,
          this.form.localizacao_cidade,
          this.form.localizacao_estado,
          this.form.localizacao_data_inicio,
          this.form.localizacao_data_fim,
          this.form.cpf_cnpj_responsavel,
          this.form.senha_responsavel,
          this.form.razao_social,
          this.form.banco,
          this.form.agencia,
          this.form.conta,
          this.form.pix,
          this.form.solicitacao_pendente,
          this.form.localizacao_bairro,
          this.form.localizacao_referencia,
          this.form.cnpj,
          this.form.nome_fantasia,
          this.form.sede_bairro,
          this.form.sede_telefone,
          this.form.proprietario_nome,
          this.form.proprietario_cpf,
          this.form.proprietario_telefone,
          this.form.proprietario_email,
          this.form.data_fundacao //informação se vai aprovar a solicitação
        )
        .then(
          (response) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.$wait.end("inprocessSave");

            if (this.validateJSON(response)) {
              if (response.success) {
                this.toastSuccess("Salvo com sucesso");
                this.$router.push(`/equipamento/edit/`+response.id);
                this.form.solicitacao_pendente=false;
                this.form_old.nome = this.form.nome;
                this.form_old.endereco = this.form.endereco;
                this.form_old.cidade = this.form.cidade;
                this.form_old.estado = this.form.estado;
                this.form_old.localizacao = this.form.localizacao;
                this.form_old.cep = this.form.cep;
                this.form_old.site = this.form.site;
                this.form_old.telefone_equipamento = this.form.telefone_equipamento;
                this.form_old.email_equipamento = this.form.email_equipamento;
                this.form_old.whatsapp = this.form.whatsapp;
                this.form_old.instagran = this.form.instagran;
                this.form_old.facebook = this.form.facebook;
                this.form_old.qtde_integrantes = this.form.qtde_integrantes;
                this.form_old.detalhes = this.form.detalhes;
                this.form_old.link_ingresso_fisico = this.form.link_ingresso_fisico;
                this.form_old.link_ingresso_online = this.form.link_ingresso_online;
                this.form_old.responsavel = this.form.responsavel;
                this.form_old.email_responsavel = this.form.email_responsavel;
                this.form_old.celular_responsavel = this.form.celular_responsavel;
                this.form_old.telefone_fixo_responsavel = this.form.telefone_fixo_responsavel;
                this.form_old.status = this.form.status;
                this.form_old.link_canal_youtube = this.form.link_canal_youtube;
                this.form_old.link_tiktok = this.form.link_tiktok;
                this.form_old.link_video1 = this.form.link_video1;
                this.form_old.link_video2 = this.form.link_video2;
                this.form_old.tab_tipo_equipamento_id = this.form.tab_tipo_equipamento_id;
                this.form_old.addedImages = this.form.images;
                this.form_old.localizacao_cidade=this.form.localizacao_cidade;
                this.form_old.localizacao_estado=this.form.localizacao_estado;
                this.form_old.cpf_cnpj_responsavel=this.form.cpf_cnpj_responsavel;            
                this.form_old.localizacao_data_inicio= this.form.localizacao_data_inicio;
                this.form_old.localizacao_data_fim=this.form.localizacao_data_fim;
                this.form_old.razao_social=this.form.razao_social;
                this.form_old.banco=this.form.banco;
                this.form_old.agencia=this.form.agencia;
                this.form_old.conta=this.form.conta;
                this.form_old.pix=this.form.pix;
                this.form_old.localizacao_bairro=this.form.localizacao_bairro;
                this.form_old.localizacao_referencia=this.form.localizacao_referencia;
                this.form_old.cnpj=this.form.cnpj;
                this.form_old.nome_fantasia=this.form.nome_fantasia;
                this.form_old.sede_bairro=this.form.sede_bairro;
                this.form_old.sede_telefone=this.form.sede_telefone;
                this.form_old.proprietario_nome=this.form.proprietario_nome;
                this.form_old.proprietario_cpf=this.form.proprietario_cpf;
                this.form_old.proprietario_telefone=this.form.proprietario_telefone;
                this.form_old.proprietario_email=this.form.proprietario_email;
                this.form_old.data_fundacao=this.form.data_fundacao;


              } else {
                this.toastError(response.msg);
              }
            }
          },
          (error) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.$wait.end("inprocessSave");
            this.toastError("Falha na execução.");
          }
        );
    },
    get() {
      if (this.processing) return;

      this.processing = true;

      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      equipamentoService.get(this.id).then(
        (response) => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");

          if (this.validateJSON(response)) {
            this.form.solicitacao_pendente = response.form.solicitacao_pendente;
            this.form.senha_responsavel = response.form.senha_responsavel;
            this.form.loaded = true;
            this.form.nome = response.form.nome;
            this.form.endereco = response.form.endereco;
            this.form.cidade = response.form.cidade;
            this.form.estado = response.form.estado;
            this.form.localizacao = response.form.localizacao;
            this.form.cep = response.form.cep;
            this.form.site = response.form.site;
            this.form.telefone_equipamento = response.form.telefone_equipamento;
            this.form.email_equipamento = response.form.email_equipamento;
            this.form.whatsapp = response.form.whatsapp;
            this.form.instagran = response.form.instagran;
            this.form.facebook = response.form.facebook;
            this.form.qtde_integrantes = response.form.qtde_integrantes;
            this.form.detalhes = response.form.detalhes;
            this.form.link_ingresso_fisico = response.form.link_ingresso_fisico;
            this.form.link_ingresso_online = response.form.link_ingresso_online;
            this.form.responsavel = response.form.responsavel;
            this.form.email_responsavel = response.form.email_responsavel;
            this.form.celular_responsavel = response.form.celular_responsavel;
            this.form.telefone_fixo_responsavel = response.form.telefone_fixo_responsavel;
            this.form.status = response.form.status;
            this.form.link_canal_youtube = response.form.link_canal_youtube;
            this.form.link_tiktok = response.form.link_tiktok;
            this.form.link_video1 = response.form.link_video1;
            this.form.link_video2 = response.form.link_video2;
            this.form.tab_tipo_equipamento_id = response.form.tab_tipo_equipamento_id;
            this.form.addedImages = response.form.images;
            this.form.localizacao_cidade=response.form.localizacao_cidade;
            this.form.localizacao_estado=response.form.localizacao_estado;
            this.form.cpf_cnpj_responsavel=response.form.cpf_cnpj_responsavel;            
            this.form.localizacao_data_inicio= response.form.localizacao_data_inicio;
            this.form.localizacao_data_fim=response.form.localizacao_data_fim;
            this.form.razao_social=response.form.razao_social;
            this.form.banco=response.form.banco;
            this.form.conta=response.form.conta;
            this.form.agencia=response.form.agencia;
            this.form.pix=response.form.pix;

            this.form.localizacao_bairro=response.form.localizacao_bairro;
            this.form.localizacao_referencia=response.form.localizacao_referencia;
            this.form.cnpj=response.form.cnpj;
            this.form.nome_fantasia=response.form.nome_fantasia;
            this.form.sede_bairro=response.form.sede_bairro;
            this.form.sede_telefone=response.form.sede_telefone;
            this.form.proprietario_nome=response.form.proprietario_nome;
            this.form.proprietario_cpf=response.form.proprietario_cpf;
            this.form.proprietario_telefone=response.form.proprietario_telefone;
            this.form.proprietario_email=response.form.proprietario_email;
            this.form.data_fundacao=response.form.data_fundacao;


            this.form_old.nome = response.form_old.nome;
            this.form_old.endereco = response.form_old.endereco;
            this.form_old.cidade = response.form_old.cidade;
            this.form_old.estado = response.form_old.estado;
            this.form_old.localizacao = response.form_old.localizacao;
            this.form_old.cep = response.form_old.cep;
            this.form_old.site = response.form_old.site;
            this.form_old.telefone_equipamento = response.form_old.telefone_equipamento;
            this.form_old.email_equipamento = response.form_old.email_equipamento;
            this.form_old.whatsapp = response.form_old.whatsapp;
            this.form_old.instagran = response.form_old.instagran;
            this.form_old.facebook = response.form_old.facebook;
            this.form_old.qtde_integrantes = response.form_old.qtde_integrantes;
            this.form_old.detalhes = response.form_old.detalhes;
            this.form_old.link_ingresso_fisico = response.form_old.link_ingresso_fisico;
            this.form_old.link_ingresso_online = response.form_old.link_ingresso_online;
            this.form_old.responsavel = response.form_old.responsavel;
            this.form_old.email_responsavel = response.form_old.email_responsavel;
            this.form_old.celular_responsavel = response.form_old.celular_responsavel;
            this.form_old.telefone_fixo_responsavel = response.form_old.telefone_fixo_responsavel;
            this.form_old.status = response.form_old.status;
            this.form_old.link_canal_youtube = response.form_old.link_canal_youtube;
            this.form_old.link_tiktok = response.form_old.link_tiktok;
            this.form_old.link_video1 = response.form_old.link_video1;
            this.form_old.link_video2 = response.form_old.link_video2;
            this.form_old.tab_tipo_equipamento_id = response.form_old.tab_tipo_equipamento_id;
            this.form_old.addedImages = response.form_old.images;
            this.form_old.localizacao_cidade=response.form_old.localizacao_cidade;
            this.form_old.localizacao_estado=response.form_old.localizacao_estado;
            this.form_old.cpf_cnpj_responsavel=response.form_old.cpf_cnpj_responsavel;            
            this.form_old.localizacao_data_inicio= response.form_old.localizacao_data_inicio;
            this.form_old.localizacao_data_fim=response.form_old.localizacao_data_fim;
            this.form_old.razao_social=response.form_old.razao_social;
            this.form_old.banco=response.form_old.banco;
            this.form_old.agencia=response.form_old.agencia;
            this.form_old.conta=response.form_old.conta;
            this.form_old.pix=response.form_old.pix;
            this.form_old.localizacao_bairro=response.form_old.localizacao_bairro;
            this.form_old.localizacao_referencia=response.form_old.localizacao_referencia;
            this.form_old.cnpj=response.form_old.cnpj;
            this.form_old.nome_fantasia=response.form_old.nome_fantasia;
            this.form_old.sede_bairro=response.form_old.sede_bairro;
            this.form_old.sede_telefone=response.form_old.sede_telefone;
            this.form_old.proprietario_nome=response.form_old.proprietario_nome;
            this.form_old.proprietario_cpf=response.form_old.proprietario_cpf;
            this.form_old.proprietario_telefone=response.form_old.proprietario_telefone;
            this.form_old.proprietario_email=response.form_old.proprietario_email;
            this.form_old.data_fundacao=response.form_old.data_fundacao;

            this.populateImage();
          }
        },
        (error) => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          this.toastError("Falha na execução.");
        }
      );
    },
    uploadImageSuccess(id, formData, index, fileList) {
      this.form.addedImages.forEach((e, i) => {
        if (id === e.id) {
          e.path = fileList[0].path
        }
      })
    },
    beforeRemove(id, index, done, fileList) {
      this.form.saveimage = true;
      this.form.addedImages.forEach((e, i) => {
        if (id === e.id) {
          e.path = null
        }
      })
      done();
    },
    editImage(id, formData, index, fileList) {
      setTimeout(() => {
        // this.form.saveimage = true;
        // this.form.addedImages[index].path = fileList[index].path;
      }, 100);
    },
    dataChange(data) {
      console.log(data);
    },
    populatetipoequipamento() {
      this.showWaitAboveAll();
      tipoequipamentoService.select(this.getLoggedId()).then(
        (response) => {
          this.hideWaitAboveAll();

          if (this.validateJSON(response)) {
            this.selects.tipoequipamento = response;
          }
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    populateImage() {
      Vue.nextTick().then((response) => {
        this.$wait.start("inprocess");
        this.showWaitAboveAll();

        equipamentoService.base64(this.id, "ori").then(
          (response) => {
            this.hideWaitAboveAll();
            this.$wait.end("inprocess");

            if (this.validateJSON(response)) {
              this.form.images = response
              this.imageobj();
            }
          },
          (error) => {
            this.hideWaitAboveAll();
            this.$wait.end("inprocess");
            this.toastError("Falha na execução.");
          }
        );
      });
    },
    imageobj() {
      Vue.nextTick().then((response) => {
        let id = 0
        this.form.images.forEach((item, idx) => {
          if (item.path.length > 0) {
            if (item.id === 'logo') {
              this.pathImageLogo = [{ default: 1, highlight: 1, name: 'default.jpg', path: item.path }]
            }
            if (item.id === 'icone') {
              this.pathImageIcone = [{ default: 1, highlight: 1, name: 'default.jpg', path: item.path }]
            }
            if (item.id === 'image1') {
              this.pathImageImage1 = [{ default: 1, highlight: 1, name: 'default.jpg', path: item.path }]
            }
            if (item.id === 'image2') {
              this.pathImageImage2 = [{ default: 1, highlight: 1, name: 'default.jpg', path: item.path }]
            }
          }
        })
        this.idupload++;
      });
    },
  },
};
</script>

<style>
.Password {
  max-width: 400px;
  margin: 0 0 !important;
}
.bannerMessagePromo {
  width: 100%;
  border: 1px solid rgba(0, 40, 100, 0.12);
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  height: 143px;
}
.containerMessagePromo {
  height: 200px;
  margin-bottom: 50px;
  margin-left: 0px;
}
.red{
  color:red;
      margin-top: -10px;
}
.red i{ padding-right:5px}
.image-edit {
  display: none !important;
}
</style>
