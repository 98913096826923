import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const equipamentoService = {
  get,
  list,
  base64,
  save,
  recusar,
  remove  
}

function remove(loggedId
    ,id_equipamento) {

    let url = config.api + `/v1/admin/equipamento/remove`;

    let obj = {
        loggedId
        ,id_equipamento
    };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
}

function list(search, currentPage, perPage, idEvent, __sortOrder, __sortField) {
    let url = config.api + `/v1/admin/equipamento/list?search=${search}&__sortOrder=${__sortOrder}&__sortField=${__sortField}`;
    if (idEvent) {
        url += `&idEvent=${idEvent}`
    }
    url = config.system.applyPagination(url, currentPage, perPage);

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}

function get(id) {
    let url = config.api + `/v1/admin/equipamento/get?id=${id}`;
    
    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function recusar(loggedId, id ) {
    let url = config.api + `/v1/admin/equipamento/recusar`;

    let obj = {
        loggedId,
        id: id != undefined && id != null ? id : '',
       }

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
}
function save(loggedId, id, saveimage,images,tab_tipo_equipamento_id,status, nome, endereco, cidade, estado, cep, site, telefone_equipamento, email_equipamento
    ,whatsapp, instagran, facebook, qtde_integrantes, detalhes, link_ingresso_fisico, link_ingresso_online, responsavel,  email_responsavel, 
    celular_responsavel, telefone_fixo_responsavel,localizacao, link_canal_youtube, link_video1, link_video2, link_tiktok,    
    localizacao_cidade,
    localizacao_estado,
    localizacao_data_inicio,localizacao_data_fim,
    cpf_cnpj_responsavel,
    senha_responsavel,razao_social,banco,agencia,conta,pix,aprovar, localizacao_bairro,
    localizacao_referencia,
    cnpj,
    nome_fantasia,
    sede_bairro,
    sede_telefone,
    proprietario_nome,
    proprietario_cpf,
    proprietario_telefone,
    proprietario_email,
    data_fundacao
    ) {
    let url = config.api + `/v1/admin/equipamento/save`;

    let obj = {
        loggedId,
        id: id != undefined && id != null ? id : '',
        saveimage,
        images,
        tab_tipo_equipamento_id,
        status: (status ? 1 : 0),
       nome, endereco, cidade, estado, cep, site, telefone_equipamento, email_equipamento
    ,whatsapp, instagran, facebook, qtde_integrantes, detalhes, link_ingresso_fisico, link_ingresso_online, responsavel, email_responsavel, 
    celular_responsavel, telefone_fixo_responsavel,localizacao, link_canal_youtube, link_video1, link_video2, link_tiktok ,localizacao_cidade,
    localizacao_estado,
    localizacao_data_inicio,localizacao_data_fim,
    cpf_cnpj_responsavel,
    senha_responsavel,razao_social,banco,agencia,conta,pix,aprovar,localizacao_bairro,
    localizacao_referencia,
    cnpj,
    nome_fantasia,
    sede_bairro,
    sede_telefone,
    proprietario_nome,
    proprietario_cpf,
    proprietario_telefone,
    proprietario_email,
    data_fundacao }

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
}

function base64(id, type) {
    let url = config.api + `/v1/admin/equipamento/base64`;

    let obj = { id, type };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}
