<template>
    <div v-if="mayIsee">
      <b-container class='equipamento-container'>
        <b-row>
          <b-col>
              <b-row>
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Buscar:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="name"
                                  maxlength="100"
                                  @keyup.enter.native="search"
                                  v-model="form.search"
                                  placeholder="Digite o nome ou endereço">
                      </b-form-input>
                      <b-button class='equipamento-btn' type="button" variant="primary" size="sm" @click="search">
                        <v-wait for="inprocess">
                            <template slot="waiting">
                                Consultando...
                            </template>
                        </v-wait>
                        <span v-if="!processing">Consultar</span>
                      </b-button>

                      <b-button v-if="grids.equipamentos.items.length > 0" class='equipamento-btn' type="button" variant="outline-primary" size="sm" @click="exportData">
                        <v-wait for="exportInProcess">
                            <template slot="waiting">
                                Exportando...
                            </template>
                        </v-wait>
                        <span v-if="!exportingData">Exportar</span>
                      </b-button>
                  </b-input-group>
              </b-row>
              <b-row v-if="grids.equipamentos.items.length > 0" class="mt-3">
                <b-input-group size="sm">
                    <h6>Total de Registros: {{ grids.equipamentos.items[0].totalCount  }}</h6>
                </b-input-group>
              </b-row>
          </b-col>

        </b-row>
        <b-row>
          <b-table striped="striped"
                  outlined="outlined"
                  class="fontSize tableClicked bg-white"
                  small="small"
                  hover="hover"
                  responsive
                  show-empty
                  no-local-sorting
                  empty-text="Não foram encontrados registros."
                  :per-page="grids.equipamentos.perPage"
                  v-if="grids.equipamentos.loaded"
                  :items="grids.equipamentos.items"
                  :fields="grids.equipamentos.fields"
                  @sort-changed="handleCustomSort($event)"
                  >

              <template slot="actions" slot-scope="data">
                  <span v-if="!mayI('equipamento-add')">-</span>
                  <b-button-group size="sm" v-if="mayI('equipamento-add')">
                      <b-button title="Editar" v-if="mayI('equipamento-add')" @click.stop="edit(data.item,$event.target)">
                        <span v-if="!processing">
                          Editar
                        </span>
                        <v-wait for="inprocess">
                            <template slot="waiting">
                                Carregando...
                            </template>
                        </v-wait>
                      </b-button>
                    <b-button-group size="sm" v-if="mayI('equipamento-add')">
                      <b-button variant="danger" title="Excluir" v-if="data.item.status!=1" @click.stop="remove(data.item)">Excluir</b-button>
                  </b-button-group>
                  </b-button-group>
              </template>


          </b-table>
          <b-pagination @change="pagto" size="sm" v-if="grids.equipamentos.loaded && grids.equipamentos.total>grids.equipamentos.perPage" :total-rows="grids.equipamentos.total" v-model="grids.equipamentos.currentPage" :per-page="grids.equipamentos.perPage">
          </b-pagination>
        </b-row>
      </b-container>
    </div>
</template>

<script>
import Vue from "vue";
import VueHead from 'vue-head';
import config from "@/config";
import { func } from "@/functions";
import { equipamentoService } from '../../components/common/services/equipamento';
import Moment from "moment";

Vue.use(VueHead);

export default {
  mixins: [func],
  name: 'equipamento-list',
  head: {
    title: function () {
      return { 
        inner: `Equipamentos | Admin`,
        separator: " | ",
        complement: "Equipamento - Lista",
      }
    },
  },
  computed: {
    mayIsee() {
      return this.mayI('equipamento-viewer', 'equipamento-add', 'equipamento-add-auth');
    }
  },
  methods: {
    edit(item) {
      this.$router.push(`/equipamento/edit/${item.id}`);
    },
     remove(data) {
      
      if (this.processing) return;
      this.$swal({
          type: 'question',
          text: `Deseja excluir o equipamento:  ${data.nome}?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          preConfirm: () => {
            this.removeinternal(data.id);
          },
      });
    },
    removeinternal(code) {
      if (this.processing) return;

      this.showWaitAboveAll();

      if (code != '') {
        equipamentoService.remove(this.getLoggedId(),code).then(
          response => {
            this.hideWaitAboveAll();
            this.processing = false;

            if (this.validateJSON(response)) {
              if (response.success == true) {
                this.toastSuccess(response.msg);
                 this.search();
                
                EventBus.$emit('reloadinfo', true);
              }
              else {
                this.toastError(response.msg);
              }
            }
          },
          error => {
            this.hideWaitAboveAll();
            this.processing = false;
            this.toastError("Falha na execução.");
          }
        );        
      }
    },
    pagto(to) {
      this.grids.equipamentos.currentPage = to;
      this.search();
    },
    /**
     * Exports data to an Excel file.
     * Accumulates data from all pages fetched from the server and exports it to an Excel file.
     * The export was made this way because the server has an outadated version of the XLSX library and for compatibility reasons, the export was made on frontend.
     * Currently the length of the data is limited and the performance is not affected.
     * @throws Error if no data is available to fetch.
     */
    exportData() {
      // Throw an error if there is no data available to fetch
      if (!this.grids.equipamentos.total) throw new Error('No data to fetch');
      
      // Prevent multiple export operations from running simultaneously
      if (this.exportingData) return;

      // Initialize an array to accumulate data and define column names
      const data = [];
      const columns  = {
        "Nome do Equipamento": "nome",
        "Tipo Equipamento": "tipo_equipamento",
        "Endereço": "endereco",
        "Cidade": "cidade",
        "Estado": "estado",
        "CEP": "cep",
        "Site": "site",
        "Telefone do Equipamento": "telefone_equipamento",
        "Email do Equipamento": "email_equipamento",
        "WhatsApp": "whatsapp",
        "Instagram": "instagran",
        "Facebook": "facebook",
        "Quantidade de Integrantes": "qtde_integrantes",
        "Detalhes": "detalhes",
        "Link para Ingresso Físico": "link_ingresso_fisico",
        "Link para Ingresso Online": "link_ingresso_online",
        "Responsável": "responsavel",
        "Email do Responsável": "email_responsavel",
        "Celular do Responsável": "celular_responsavel",
        "Telefone Fixo do Responsável": "telefone_fixo_responsavel",
        "Data de Criação": "created",
        "Data de Última Alteração": "updated",
        "Atualizado Por": "atualizado_por",
        "Localização": "localizacao",
        "Cidade da Localização": "localizacao_cidade",
        "Estado da Localização": "localizacao_estado",
        "Data de Início da Localização": "localizacao_data_inicio",
        "Data de Fim da Localização": "localizacao_data_fim",
        "CPF ou CNPJ do Responsável": "cpf_cnpj_responsavel",
        "Razão Social": "razao_social",
        "Banco": "banco",
        "Agência": "agencia",
        "Conta": "conta",
        "PIX": "pix",
        "Bairro da Localização": "localizacao_bairro",
        "Referência da Localização": "localizacao_referencia",
        "CNPJ": "cnpj",
        "Nome Fantasia": "nome_fantasia",
        "Bairro da Sede": "sede_bairro",
        "Telefone da Sede": "sede_telefone",
        "Nome do Proprietário": "proprietario_nome",
        "CPF do Proprietário": "proprietario_cpf",
        "Telefone do Proprietário": "proprietario_telefone",
        "Email do Proprietário": "proprietario_email",
        "Data de Fundação": "data_fundacao"
      };
      

      // Store the reference to the component instance
      const self = this;

      // Fetch data from the server and export it to an Excel file
      this.fetchData(data, columns)
        .then(() => {
          // Convert the accumulated data to XLSX format
          const ws = XLSX.utils.json_to_sheet(data, { header: Object.keys(data[0]) });
          const wb = XLSX.utils.book_new();

          // Convert column widths to the format expected by SheetJS
          const wscols = [];
          Object.keys(columns).forEach((columnName) => {
            const columnWidth = columnName.length + 15;
            wscols.push({ wch: columnWidth });
          });
          ws["!cols"] = wscols;

          // Append the worksheet to the workbook
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          // Generate a filename with the current date and time
          const currentDate = Moment();
          const formattedDate = currentDate.format('DD-MM-YYYY HH-mm-ss');
          const fileName = `equipamentos_${formattedDate}.xlsx`;

          // Export the workbook to a file
          XLSX.writeFile(wb, fileName);
        })
        .catch(error => {
          console.error('Error exporting data:', error);
        })
        .finally(() => {
          // Mark the end of the export process
          self.$wait.end("exportInProcess");
          self.exportingData = false;
          self.hideWaitAboveAll();
        });
    },

    /**
     * Fetches data from the server.
     * Fetches data for all pages and accumulates it in the provided array.
     * @param {Array} accumulatedData - The array to accumulate the fetched data.
     * @returns {Promise} - A promise that resolves when all data is fetched and accumulated.
     */
    async fetchData(accumulatedData, columns) {
      // Start the export process
      this.$wait.start("exportInProcess");
      this.exportingData = true;
      this.showWaitAboveAll();
      // Initialize variables
      let currentPage = 1;
      const totalPages = this.grids.equipamentos.total;
      const perPage = 1000;
      const search = this.form.search;
      const sortOrder = this.grids.equipamentos.sortOrders;
      const sortKey = this.grids.equipamentos.sortKey;

      // Iterate over all pages and fetch data
      while (currentPage <= totalPages) {
        // Fetch data for the current page
        const pageData = await equipamentoService.list(search, currentPage, perPage, null, sortOrder, sortKey);
        
        // Process the fetched data and extract only the specified columns
        const processedData = pageData.map(item => {
          const orderedItem = {};
          Object.keys(columns).forEach(columnKey => {
            orderedItem[columnKey] = item[columns[columnKey]];
          });
          return orderedItem;
        });
        
        // Accumulate the processed data
        accumulatedData.push(...processedData);
        
        // Increment the current page number
        currentPage++;

        // Check if the fetched data is less than the expected per page
        if (pageData.length < perPage) {
          // If the fetched data is less than expected per page, stop fetching more pages
          break;
        }
      }
    },
    handleCustomSort(event) {
      this.grids.equipamentos.sortKey = event.sortBy;
      this.grids.equipamentos.sortOrders = event.sortDesc ? 'DESC' : 'ASC';
      this.search();
    },
    search() {
      if (this.processing) return;

      this.grids.equipamentos.processing = true;
      this.processing = true;

      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      let search = this.form.search;

      if (search == "@me") {
        search = this.ls_get("name");
      }

      equipamentoService.list(search, this.grids.equipamentos.currentPage, this.grids.equipamentos.perPage, null, this.grids.equipamentos.sortOrders, this.grids.equipamentos.sortKey).then(
        response => {
          this.processing = false;
          this.grids.equipamentos.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");

          if (this.validateJSON(response))
          {
              this.grids.equipamentos.loaded = true;
              this.grids.equipamentos.items = response;
              this.grids.equipamentos.currentPage = response.length == 0 ? 1 : response[0]["currentPage"];
              this.grids.equipamentos.total = response.length == 0 ? 0 : response[0][config.system.totalCount];
          }
        },
        error => {
          this.grids.equipamentos.processing = false;
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          this.toastError("Falha na execução.");
        }
      );      
    }
  },
  data () {
    return {
        processing: false,
        loading: false,
        exportingData: false,
        form: {
          search: '',
        },
        grids: {
            equipamentos: {
                processing: false,
                loaded: false,
                total: 0,
                currentPage: 1,
                perPage: 10,
                sortOrders: 'DESC',
                sortKey: 'updated',
                items: [],
                fields: {
                    nome: { label: 'Nome da Equipamento', sortable: true, sortDirection: 'asc'},
                    tipo_equipamento: { label: 'Tipo de Equipamento', sortable: true },
                    cidade: { label: 'Cidade', sortable: true },
                    estado: { label: 'Estado', sortable: true },
                    endereco: { label: 'Endereço', sortable: true },
                    created: { label: 'Criado em', sortable: true },
                    updated: { label: 'Atualizado em', sortable: true },
                    atualizado_por: { label: 'Atualizado por', sortable: true },
                    actions: { label: 'Ações' }
                },
            }
        }
    }
  }
}
</script>

<style>
.equipamento-container {
  font-size: 0.8rem !important;
}
.equipamento-btn {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
</style>
